<template>
    <div class="info-wrap pt-1 blue-bg">
        <div class="medium-container">
            <div class="logo">
              <img src="@/assets/images/general/logo-dots.svg"/>
            </div>
            <div class="oops-image">
              <img src="@/assets/images/general/oops.svg"/>
            </div>
            <div class="info-text">
                <h3 v-html=" __t('weekend-info.title') "></h3>
                <h1 class="count-down"> {{ countdown.days }} : {{ countdown.hours }} : {{ countdown.minutes }} : {{ countdown.seconds }} </h1>
                <h3>{{ __t('weekend-info.description') }}</h3>
                <h3>In the meantime don’t forget to add us to your homescreen</h3>
                <h3>xox</h3>
                <p v-if="token.token.length == 0" class="mt-5">{{ __t('weekend-info.social') }}</p>
            </div>
            <div class="space-border">

            </div>
            <div class="actions">
              <template v-if="token.token.length == 0">
                  <form @submit.prevent="handleSubmit" v-if=" !isFormSubmit">
                      <div class="form-group">
                          <input
                          type="text" name="email"
                          @keydown="clearTo"
                          @keyup="validateEmail"
                          class="form-control"
                          :class="{ 'is-invalid': !isValidEmail }"
                          v-model="email"
                          placeholder="Please enter your e-mail"/>
                          <small v-if="!isValidEmail" class="form-text invalid-feedback">{{ __t('sign-up.not-valid-email') }}</small>
                      </div>
                      <input type="submit" class="btn-default" value="SIGN ME UP"/>
                  </form>
                  <div class="signup-response" v-else>
                      <img src="@/assets/images/general/WIO_Sticker_SLAYYY_w-bg.png" class="mb-2"/>
                      <a href="#" class="btn-default" v-text="isEmailAlreadyRegister ? 'YOU ARE ALREADY SIGNED IN' : 'YOU SIGNED IN'"></a>
                  </div>
              </template>
                <a href="https://www.instagram.com/wearitoutapp/?igshid=YmMyMTA2M2Y%3D" class="btn-default" target="_blank">Instagram</a>
                <a :href="getBlogUrl" class="btn-default" target="_blank">Blog</a>
            </div>
        </div>
    </div>
</template>

<script>
import { Http } from '@/helpers/http-helper'
import { mapState } from 'vuex'
export default {
  name: 'Info',
  metaInfo: {
    title: 'Info',
    meta: [
      {
        name: 'description',
        content: 'Let our inclusive community help you decide what to wear! Express your personal style and share your looks on the app'
      }
      // Add other meta tags as needed
    ]
  },
  data () {
    return {
      email: '',
      isValidEmail: true,
      isFormSubmit: false,
      targetDateTime: null,
      countdown: null,
      isEmailAlreadyRegister: false
    }
  },
  computed: {
    ...mapState('auth', ['token']),
    getBlogUrl () {
      return process.env.VUE_APP_REDIRECT_BLOG_URL
    }
  },
  methods: {
    handleSubmit () {
      this.validateEmail()
      if (this.isValidEmail && this.email.length !== 0) {
        Http.post('/newsletter', { 'email': this.email })
          .then(response => {
            if (response.status === 201) {
              this.email = ''
              this.isFormSubmit = true
            }
          }).catch(err => {
            let response = err.response
            if (response.status === 422) {
              this.isFormSubmit = true
              this.isEmailAlreadyRegister = true
            }
          })
      }
    },
    validateEmail (s = null) {
      this.settimeout = setTimeout(() => {
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.isValidEmail = re.test(this.email)
      }, s || 1000)
    },
    startCountDown () {
      this.intervalId = setInterval(this.updateCountdown, 1000)
    },
    updateCountdown () {
      const currentTime = new Date().getTime()
      const timeDifference = this.targetDateTime.getTime() - currentTime
      if (timeDifference <= 0) {
        // Countdown has reached the target date and time
        clearInterval(this.intervalId)
        this.countdown = null
        this.$router.push({ path: '/' })
      } else {
        // Calculate remaining days, hours, minutes, and seconds
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)

        this.countdown = {
          days,
          hours,
          minutes,
          seconds
        }
      }
    },
    clearTo () {
      clearTimeout(this.settimeout)
    }
  },
  created () {
    this.targetDateTime = new Date(localStorage.getItem('countdownDate'))
    this.startCountDown(this.targetDateTime)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  }
}
</script>
